<template>
  <b-card
    v-if="item"
    :title="item.title"
    :sub-title="isNew ? '新建' : `Updated at ${item.published}`"
  >
    <b-row>
      <!-- law -->
      <b-col lg="6" class="d-flex">
        <span class="mr-1">适用法律：</span>
        <b-form-checkbox
          v-for="(lawItem, lawIdx) in lawOptions"
          v-model="item.apply"
          :value="lawItem"
          :key="lawIdx"
          class="mr-1"
        >
          {{ lawItem }}
        </b-form-checkbox>
      </b-col>
     
      <b-col lg="6" class="text-right">
        <b-button variant="primary" @click="fileClicked('pdf')" class="mr-1">
          导入PDF文档
        </b-button>
        <b-button variant="primary" @click="fileClicked('doc')">
          导入Word文档
        </b-button>
        <div class="d-none">
          <b-form-file
            id="upload"
            ref="upload"
            accept=".doc, .docx"
            v-model="uploadFile"
            @input="fileChange"
          />
        </div>
      </b-col>
      </b-row>

      <b-row>
        <b-col cols="3" >
          <b-form-group label="编号:" label-for="h-code" content-cols="10" label-align="left" class="text-left">
            <b-form-input id="h-code" v-model="item.code" placeholder="编号" />
          </b-form-group>
        </b-col>
     
        <b-col cols="3" >
          <b-form-group label="标题:" label-for="h-title" content-cols="10" label-align="left">
            <b-form-input id="h-title" v-model="item.title" placeholder="标题" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
      <b-col lg="12" class="mt-1">
        <template v-if="item.attachment">
          <pdf
            v-for="i in numPages"
            :src="item.attachment"
            :page="i"
            :key="i"
            class="d-inline-block w-100"
          />
        </template>
        <quill-editor
          v-else
          v-model="item.content"
          :options="quillEditorOption"
        />
      </b-col>

      <b-col lg="12" class="mt-1 d-flex justify-content-end">
        <b-button variant="primary" class="mr-1">保存</b-button>
        <b-button @click="onCancel">取消</b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardFooter,
  BCardTitle,
  BRow,
  BCol,
  BFormCheckbox,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BButton,
  BFormFile,
  BIcon,
  BIconAlarm,
} from "bootstrap-vue";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import pdf from "vue-pdf";

import mammoth from "mammoth";

export default {
  name: "LibraryLawDetail",
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BCardTitle,
    BRow,
    BCol,
    BFormCheckbox,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BButton,
    BFormFile,
    BIcon,
    BIconAlarm,
    quillEditor,
    mammoth,
    pdf,
  },

  data() {
    return {
      id: this.$route.params.id,
      item: null,

      lawOptions: ["GDPR", "CCPA", "PIPL"],

      quillEditorOption: { theme: "snow", placeholder: "在这里输入内容" },

      uploadFile: null,

      // pdf
      loadingPdfTask: null,
      numPages: 0,
    };
  },

  computed: {
    isNew() {
      return this.id === "new";
    },
  },

  mounted() {},

  beforeMount() {
    if (this.id == "new") {
      const item = {
        id: 0,
        code: null,
        title: null,
        overview: null,
        content: "",
        apply: [],
        type: null,
        attachments: null,
        status: "active",
      };
      this.item = item;
    } else {
      this.$http.get("/api/lib-law/" + this.id).then((res) => {
        // console.log(res.data);
        this.item = res.data.item;

        if (this.item.attachment) {
          this.loadingPdfTask = pdf.createLoadingTask(this.item.attachment);
          this.loadingPdfTask.promise.then((pdf) => {
            this.numPages = pdf.numPages;
          });
        }
      });
    }
  },
  methods: {
    onCancel() {
      this.$router.push({ name: "library-law" });
    },

    fileClicked(fileTyoe) {
      let fileDom = document.getElementById("upload");
      if (fileTyoe === "pdf") {
        fileDom.accept = ".pdf";
      } else if (fileTyoe === "doc") {
        fileDom.accept = ".doc, .docx";
      }
      fileDom.click();
    },

    fileChange(file) {
      console.log(file);
      if (!file) return;

      if (file.type == "application/pdf") {
        // TODO: upload the pdf to the server first
        //this.readFileInputEventAsArrayBuffer(file, this.convertToHtml);
      } else {
        this.readFileInputEventAsArrayBuffer(file, this.convertToHtml);
        mammoth
          .convertToHtml(file)
          .then((result) => {
            // console.log(result.value);
            this.item.content = result.value;
          })
          .done();
      }
    },

    convertToHtml(arrayBuffer) {
      mammoth
        .convertToHtml({ arrayBuffer: arrayBuffer })
        .then((result) => {
          // console.log(result.value);
          this.item.content = result.value;
        })
        .done();
    },

    readFileInputEventAsArrayBuffer(file, callback) {
      var reader = new FileReader();

      reader.onload = function (loadEvent) {
        var arrayBuffer = loadEvent.target.result;
        callback(arrayBuffer);
      };

      reader.readAsArrayBuffer(file);
    },
  },
};
</script>

<style >
.ql-editor {
  min-height: 320px;
}
</style>